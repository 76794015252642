.App {
  text-align: center;
 padding-top: 30px;
    
  }
  body {
    font-family: 'Ubuntu', sans-serif;
    color:#3a3a3a
    /* Add other global styles as needed */
  }
.salute{
  font-size: 2.5rem;
  font-weight: 400;
  margin:0;
}
.image-size{
  height: 150px;
  width:150px;
}
.logo-size{
height: 20px;
width: 20px;
margin:8px 10px 0px 10px;
}
.details{
  width: 85%;
  margin: 0 auto;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
}
.name{
  font-size: 1.25rem;
  font-weight: 400;
  margin: 10px 0px 10px 0px;
}

  

a{
  text-decoration: none;
  margin: 0;
}





@media screen and(max-width:768px){
  .details{
    width: 80%;
    text-align:justify;
  }
  .name{
    font-size: 1rem;
    font-weight: 400;
    margin: 10px 0px 10px 0px;
  }
 
  .salute{
    font-size: 2rem;
    font-weight: 400;
    margin:0;
  }
}
@media screen and (min-width:768px) and (max-width:1024px) {
  .details{
    width: 70%;
  }
  .name{
    font-size: 1.5rem;
    font-weight: 400;
    margin: 10px 0px 10px 0px;
  }
}
@media screen and (min-width:1024px) {
  .details{
    width: 50%;
  }
  .name{
    font-size: 1.5rem;
    font-weight: 400;
    margin: 10px 0px 10px 0px;
  }
}